.App {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    // height: 100vh;

    input {
        font-family: "Prompt", sans-serif;
    }

    button {
        font-family: "Prompt", sans-serif;
    }

    select {
        font-family: "Prompt", sans-serif;
    }
}
.ant-layout-content {
    height: 1000px;
}