.banner {
    background-color: #ffffff;
    padding-bottom: 20px;
}

.product {
    background-color: #ffffff;
    height: 500px;
}

.ant-upload.ant-upload-select-picture-card {
    width: 340px;
    height: 340px;
}

.ant-upload-list-picture-card-container {
    width: 340px;
    height: 340px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    width: 340px;
    height: 340px;
}