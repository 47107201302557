.site-layout-sub-header-background {
    background: #fff;

    .dropdown-menu {
        display: flex;
        justify-content: flex-end;
    }

    .avatar {
        margin-right: 8px;
    }
}

.ant-dropdown {
    min-width: 180px!important;
}