.login-form {
    max-width: 300px;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    label {
        color: #4E6284;
    }

    .login-form-forgot {
        float: right;
        font-size: 12px;
        color: #888888;
    }

    .ant-col-rtl .login-form-forgot {
        float: left;
    }

    .login-form-remember {
        span {
            font-size: 12px;
        }
    }

    .login-form-button {
        width: 100%;
    }

    .logo-login {
        display: flex;
        justify-content: center;
    }
}